import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { Typography } from '@mui/material';

const NotificationDescription = ({ description, notificationData }) => {
  const safeInput = DOMPurify.sanitize(description);

  return (
    <Typography
      color="#605E70"
      fontSize={12}
      fontWeight={400}
      dangerouslySetInnerHTML={{
        __html: safeInput.replace(/\*\*(.+?)\*\*/g, (_, dynamicTag) => {
          const [anchorLabel, anchorLinkData] = dynamicTag?.split('|') ?? [];
          const anchorLink = notificationData[anchorLinkData] ?? '';
          const safeLink = DOMPurify.sanitize(anchorLink);

          return ` <a href='${safeLink}' target='_blank' rel='noopener noreferrer'>${anchorLabel}</a>`;
        }),
      }}
    />
  );
};

NotificationDescription.propTypes = {
  description: PropTypes.string,
  notificationData: PropTypes.object,
};

NotificationDescription.defaultProps = {
  description: '',
  notificationData: {},
};

export default NotificationDescription;
